import React from 'react';
import './Home.css';

const jobs = [
  {
    company: 'CopyFoundry',
    title: 'Co-Founder/Tech Lead',
    dates: {
      start: '2022.05',
    },
  },
  {
    company: 'Staat',
    title: 'Co-Founder/Tech Lead',
    dates: {
      start: '2020.08',
      end: '2022.06',
    },
  },
  {
    company: 'Collage',
    title: 'Sr Software Engineer',
    dates: {
      start: '2019.07',
      end: '2020.08',
    },
  },
  {
    company: 'Honeywell',
    title: 'Sr Software Engineer',
    dates: {
      start: '2019.04',
      end: '2019.07',
    },
  },
  {
    company: 'Honeywell',
    title: 'Software Engineer',
    dates: {
      start: '2017.04',
      end: '2019.04',
    },
  }
];

export default function Home() {
  return (
    <div className="home">
      <div className="bannerWrapper"><span></span>
      <p className="banner">
        <span role="img" aria-label="wave">👋🏼</span>&nbsp;
        HI, I'M PAUL.&nbsp;
        <span role="img" aria-label="wave">👊🏼</span>&nbsp;
        HI, I'M POL.&nbsp;
        <span role="img" aria-label="wave">🙇🏻 </span>&nbsp;
        HI, I'M 폴.&nbsp;
        <span role="img" aria-label="wave">🔨</span>&nbsp;
        This site is still under construction.&nbsp;
      </p>
      <p className="banner">
        <span role="img" aria-label="wave">👋🏼</span>&nbsp;
        HI, I'M PAUL.&nbsp;
        <span role="img" aria-label="wave">👊🏼</span>&nbsp;
        HI, I'M POL.&nbsp;
        <span role="img" aria-label="wave">🙇🏻 </span>&nbsp;
        HI, I'M 폴.&nbsp;
        <span role="img" aria-label="wave">🔨</span>&nbsp;
        This site is still under construction.&nbsp;
      </p>
      </div>
      <div className="content">
        <h2 className="headline">
          Here's what I've been <br /> up to.
        </h2>
        <div className="experience">
          <span className="line" />
          {
            jobs.map((job, i) => {
              return (
                <div className={`job ${!job.dates.end ? ' current': ''}`} data-order={i + 1}>
                  <div className="text">
                    <p className="company">{job.company}</p>
                    <p className="title">{job.title}</p>
                    <p className="dates">{`${job.dates.start} - ${job.dates.end ? job.dates.end : 'Present'}`}</p>
                  </div>
                  <span className="marker" />
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="gradientOverlay"></div>
    </div>
  )
}
