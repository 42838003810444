import React, { useState } from 'react';
import Home from './views/Home';
import './App.scss';

export default function App() {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div className={`App${darkMode ? ' darkMode' : ''}`}>
      <Home />
      <div className="darkModeToggle">
        {
          <span
            className={`toggle ${darkMode ? 'dark' : 'light'}`}
            onClick={() => setDarkMode(!darkMode)}
          >
            <span className="fill"/>
          </span>
        }
      </div>
    </div>
  );
}
